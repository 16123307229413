/* src/App.css */

/* .App {
  text-align: center;
  margin-top: 50px;
}

input[type="file"], input[type="text"] {
  margin: 10px 0;
}

button {
  cursor: pointer;
  margin: 10px;
} */

/* src/App.css */
:root {
  --body: #080808;
  --primary: #fff;
  --secondary: #8F8F8F;
  --pink: #FF87EE;
  --darkBlue: #121318;
}

.App {
  /* text-align: center; */
}

.github-corner {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
  z-index: 1000; /* Ensures it stays above other content */
}


.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.tab .logo-title {
  font-size: 2em; /* Adjust the size as needed */
  color: #333; /* Dark text for better readability */
  font-weight: bold; /* Makes the title more prominent */
  text-align: left; /* Centers the title in the tab */
  margin: 0; /* Removes default margin */
  padding: 20px 10px; /* Adds some space above and below the title */
  background: #e5e5e5; /* Light background for the tab */
  border-radius: 8px; /* Optional: rounds the corners of the tab */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  text-transform: uppercase; /* Optional: transforms text to uppercase for a stylistic choice */
  letter-spacing: 1px; /* Increases spacing between letters for a more refined look */
}

.logo-img {
  vertical-align: middle;
}

/* You might want to add some media queries for responsiveness */
@media (max-width: 768px) {
  .tab .logo-title {
    font-size: 1.5em; /* Smaller size for mobile devices */
  }
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}



/* Assuming these styles are added to App.css */

.view-ordinal {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.view-ordinal h2 {
  color: #333;
  margin-bottom: 20px;
}

.view-ordinal form {
  margin-bottom: 20px;
}

.view-ordinal input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.view-ordinal button {
  width: 100%;
  padding: 10px;
  background-color: #0055a5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-ordinal button:hover {
  background-color: #003d7a;
}

.file-details {
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.file-details h3 {
  margin-bottom: 10px;
}

.file-details p {
  margin: 5px 0;
}

.file-details img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 5px;
}

.file-details a {
  display: inline-block;
  margin-top: 10px;
  color: #0055a5;
  text-decoration: none;
}

.file-details a:hover {
  text-decoration: underline;
}

.upload-ordinal {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.upload-ordinal h2 {
  color: #333;
  margin-bottom: 20px;
}

.upload-ordinal form {
  text-align: left;
}

.upload-ordinal select,
.upload-ordinal input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

.upload-ordinal button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-ordinal button:hover {
  background-color: #45a049;
}
.network-dropdown select {
  padding: 10px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  height: 100%;
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
}
.tab section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 100px;
}
.modal-wrapper {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.4);
  z-index: 10 !important;
}
.modal {
  flex-direction: column;
  gap: 10px;
  min-width: 500px;
  border: 1px solid grey;
  border-radius: 16px;
  padding: 24px;
}
.modal-open {
  display: flex;
}
.modal-wrapper p{
  margin: 0;
}
.block-hash {
  font-size: 14px;
  font-weight: 500;
}
.modal-wrapper button {
  font-size: 16px;
  font-weight: 500;
}

/* CTA Styles in CSS */

.cta-container {
  text-align: center;
  background-color: #0055a5; /* Dark blue background */
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-message {
  font-size: 1.2em;
  margin: 0 0 20px 0;
}

.cta-button {
  background-color: #ffcc00; /* Bright yellow button */
  color: #0055a5; /* Text color */
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.cta-button:hover {
  background-color: #e6b800;
  transform: scale(1.05);
}

.ant-menu-item-divider {
  border-color: grey !important;
}

/* ANDT OVERRIDE CLASSES */
.Toastify__toast {
  border: 1px solid rgb(75, 85, 99) !important;
}
.ant-menu-item-selected {
  background: var(--pink) !important;
}
.ant-drawer-body {
  padding: 0px !important;
}
.ant-dropdown-menu-item-selected {
  background: var(--pink) !important;
  color: var(--darkBlue) !important;
}
/* ANDT OVERRIDE CLASSES */